export const billTypeOpt = [{
    text: '全部费用科目',
    value: ""
  },
  {
    text: '租金',
    value: 1
  },
  {
    text: '物业费',
    value: 5
  },
  {
    text: '租房押金',
    value: 2
  },
  {
    text: '物业押金',
    value: 6
  },
  {
    text: '退租结算',
    value: 13
  },
]

export const billStatusOpt = [{
    text: '全部收款状态',
    value: ""
  },
  {
    text: '未收款',
    value: "0"
  },
  {
    text: '部分收款',
    value: "1"
  },
  {
    text: '已收款',
    value: "2"
  },
]


// 收款状态
export const billStatusObj = {
  0: "未收款",
  1: "部分收款",
  2: "已收款",
}