<template>
  <div id="billInfo">
    <Back title="收款详情"></Back>
    <main>
      <div class="info-box">
        <p>
          <span class="info-title">租户名称:</span
          ><span>{{ billInfo.tenantName }}</span>
        </p>
        <p>
          <span class="info-title">费用科目:</span
          ><span>{{ billInfo.exacctName }}</span>
        </p>
        <p>
          <span class="info-title">应收金额:</span
          ><span>{{ billInfo.billAmount }}</span>
        </p>
        <p>
          <span class="info-title">应收日期:</span
          ><span>{{ formatterDate(billInfo.billDate, "yyyy-MM-dd") }}</span>
        </p>
        <p>
          <span class="info-title">账单状态:</span
          ><span>{{ billStatusObj[billInfo.billStatus] }}</span>
        </p>
        <p>
          <span class="info-title">计费周期:</span
          ><span
            >{{ formatterDate(billInfo.cycleStart, "yyyy-MM-dd") }}~{{
              formatterDate(billInfo.cycleEnd, "yyyy-MM-dd")
            }}</span
          >
        </p>
        <p>
          <span class="info-title">楼宇名称:</span
          ><span>{{ billInfo.unitName }}</span>
        </p>
        <p>
          <span class="info-title">房源名称:</span
          ><span>{{ billInfo.resourceNames }}</span>
        </p>
        <p>
          <span class="info-title">结算状态:</span
          ><span>{{ billInfo.invoiceStatus }}</span>
        </p>
        <p>
          <span class="info-title">相关合同编号:</span
          ><span>{{ billInfo.contractId }}</span>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getBillInfo } from "@/api/bill/bill";
import { formatterDate } from "@/utils/utils";
import { billTypeOpt, billStatusOpt, billStatusObj } from "@/db/bill";
export default {
  components: { Back },
  data() {
    return {
      formatterDate,
      billStatusObj,
      billId: this.$route.query.billId,
      billInfo: "",
    };
  },
  created() {
    this.getBillInfo();
  },

  methods: {
    getBillInfo() {
      let data = {
        billId: this.billId,
      };
      getBillInfo(data).then((res) => {
        console.log("res: ", res);
        if (res.data) {
          this.billInfo = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#billInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: hidden;
  background: url("~@/assets/images/bill/billInfoBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.info-box {
  width: 2.8rem;
  height: 5rem;
  padding-top: 1.5rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0.05rem 0;
  }
  .info-title {
    color: #999;
  }
}
</style>